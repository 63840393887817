import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Image } from 'components/atoms';

const PageNotFound = ({ className }) => {
  const description = `<p class="body-sm_thin">That can’t be right.. Looks like we’ve misplaced the page you’re looking for. That doesn’t usually happen! Seriously. We’re really good at this stuff.</p>`;
  const descriptionWithLink = `<p class="body-sm_thin">Maybe check you entered the URL properly? Or just hit the Back button. Or <a href="/"> go to the Homepage</a>. Er… that’s all we got.</p>`;

  return (
    <Section className={cn('error-page', [className])}>
      <Container>
        <Row>
          <Column
            lg="6"
            col="10"
            className="offset-lg-3 offset-1 error-page__header"
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            <Image
              src="/images/404image.svg"
              alt="error"
              title="error"
              className="error-page__header__logo"
            />
            <Text tag="h1" className="error-page__header__title headline-3">
              Yikes!
            </Text>
          </Column>

          <Column
            lg="6"
            col="10"
            className="offset-lg-3 offset-1 error-page__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <Column
            lg="6"
            col="10"
            className="offset-lg-3 offset-1 error-page__description error-page__description-with-link"
            dangerouslySetInnerHTML={{ __html: descriptionWithLink }}
          />
        </Row>
      </Container>
    </Section>
  );
};

PageNotFound.defaultProps = {
  className: '',
};

PageNotFound.propTypes = {
  className: PropTypes.string,
};

export default PageNotFound;
